<template>
  <a-row :gutter="24">
    <!-- <a-col :md="5" :sm="24">
      <a-card :bordered="false" :loading="treeLoading">
        <div v-if="this.orgTree != ''">
          <a-tree
            :treeData="orgTree"
            v-if="orgTree.length"
            @select="handleClick"
            :defaultExpandAll="true"
            :defaultExpandedKeys="defaultExpandedKeys"
            :replaceFields="replaceFields" />
        </div>
        <div v-else>
          <a-empty :image="simpleImage" />
        </div>
      </a-card>
    </a-col> -->
    <a-col :md="24" :sm="24">
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="姓名">
                  <a-input v-model="queryParam.name" allow-clear placeholder="请输入姓名" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="状态">
                  <a-select v-model="queryParam.status" allow-clear placeholder="请选择状态" default-value="0">
                    <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.code">{{
                      item.value
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <!-- <div class="table-operator">
          <a-button type="primary" icon="plus" @click="$refs.addForm.add()"
            >新增用户</a-button
          >
        </div> -->

        <s-table
          ref="table"
          size="default"
          :columns="columns"
          :data="loadData"
          :alert="true"
          :rowKey="(record) => record.id"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
          <span slot="sex" slot-scope="text">
            {{ sexFilter(text) }}
          </span>
          <!--  v-if="hasPerm('sysUser:changeStatus')" -->
          <span slot="status" slot-scope="text, record">
            <a-popconfirm
              placement="top"
              :title="text === 0 ? '确定停用该用户？' : '确定启用该用户？'"
              @confirm="() => editUserStatus(text, record)"
            >
              <a>{{ statusFilter(text) }}</a>
            </a-popconfirm>
          </span>
          <!-- <span slot="status" v-else>
            {{ statusFilter(text) }}
          </span> -->

          <span slot="action" slot-scope="text, record">
                  <a @click="$refs.editForm.edit(record)">详情</a>
                  <a-divider type="vertical" />
                  <a-popconfirm placement="topRight" title="确认重置密码？" @confirm="() => resetPwd(record)">
                    <a>重置密码</a>
                  </a-popconfirm>
                  <a-divider type="vertical" />
                  <a @click="$refs.userRoleForm.userRole(record)">授权角色</a>
                  <!-- <a-divider type="vertical" /> -->
                <!-- <a-menu-item>
                  <a @click="$refs.userRoleForm.userRole(record)">授权角色</a>
                </a-menu-item> -->
                <!-- <a-menu-item v-if="hasPerm('sysUser:grantData')">
                  <a @click="$refs.userOrgForm.userOrg(record)">授权数据</a>
                </a-menu-item> -->
                  <!-- <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysUserDelete(record)">
                    <a>删除</a>
                  </a-popconfirm> -->
          </span>
        </s-table>
        <!-- 子组件 -->
        <add-form ref="addForm" @ok="handleOk" />
        <edit-form ref="editForm" @ok="handleOk" />
        <user-role-form ref="userRoleForm" @ok="handleOk" />
        <user-org-form ref="userOrgForm" @ok="handleOk" />
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { STable } from '@/components'
import { Empty } from 'ant-design-vue'
import { getOrgTree } from '@/api/modular/system/orgManage'
import {
  getUserPage,
  queryUserPageList,
  sysUserDelete,
  sysUserChangeStatus,
  sysUserResetPwd,
} from '@/api/modular/system/userManage'

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import addForm from './addForm'
import editForm from './editForm'
import userRoleForm from './userRoleForm'
import userOrgForm from './userOrgForm'

export default {
  components: {
    STable,
    addForm,
    editForm,
    userRoleForm,
    userOrgForm,
    // sysDictTypeDropDown,
  },

  data() {
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '账号',
          dataIndex: 'account',
        },
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
        },
        {
          title: '角色',
          dataIndex: 'roleName',
        },
        {
          title: '手机',
          dataIndex: 'phone',
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        return queryUserPageList(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data.data
        })
      },
      orgTree: [],
      selectedRowKeys: [],
      selectedRows: [],
      defaultExpandedKeys: [],
      sexDictTypeDropDown: [],
      statusDictTypeDropDown: [],
      statusList: [],
      treeLoading: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      replaceFields: {
        key: 'id',
      },
    }
  },
  created() {
    /**
     * 获取到机构树，展开顶级下树节点，考虑到后期数据量变大，不建议全部展开
     */
    // getOrgTree(Object.assign(this.queryParam)).then(res => {
    //   this.treeLoading = false
    //   if (!res.success) {
    //     return
    //   }
    //   this.orgTree = res.data
    //   for (var item of res.data) {
    //     // eslint-disable-next-line eqeqeq
    //     if (item.parentId == 0) {
    //       this.defaultExpandedKeys.push(item.id)
    //     }
    //   }
    // })
    this.sysDictTypeDropDown()

    this.columns.push({
      title: '操作',
      width: '200px',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
    })
  },

  methods: {
    // 点击按钮跳转到对应页面
    handleInfoButton(pageCode){
      if(pageCode==1){
        //订单
        this.$router.push({ path:'/ordelist_admin', query:{storeId: this.recordId} })
      }else if(pageCode==2){
        // 商品
        // this.$router.push({ path:'/ordelist_admin', query:{storeId: this.recordId} })
      }else if(pageCode==3){
        // 视频
        this.$router.push({ path:'/livevideolist', query:{storeId: this.recordId} })
      }else if(pageCode==4){
        // 直播
      }
    },
    sexFilter(sex) {
      // eslint-disable-next-line eqeqeq
      const values = this.sexDictTypeDropDown.filter((item) => item.code == sex)
      if (values.length > 0) {
        return values[0].value
      }
    },
    statusFilter(status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDictTypeDropDown.filter((item) => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },

    /**
     * 获取字典数据
     */
    sysDictTypeDropDown(text) {
      sysDictTypeDropDown({ code: 'sex' }).then((res) => {
        this.sexDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDictTypeDropDown = res.data
        this.statusList = this.statusDictTypeDropDown.filter((item) => {
          return item.value != '删除'
        })
      })
    },

    /**
     * 修改用户状态
     */
    async editUserStatus(text, record) {
      // eslint-disable-next-line no-unused-vars
      let params = {
        status: text==0 ? 1 : 0,
        id: record.id
      }
      let data = await sysUserDelete(params)
      var txt = text == 0 ? '禁用' : text == 1 ? '启用' : ''
      if (data.code == 200) {
        this.$message.success(record.name+' 已' + txt)
        this.$refs.table.refresh()
      }
 
    },

    /**
     * 重置密码
     */
    resetPwd(record) {
      sysUserResetPwd({ userId: record.id }).then((res) => {
        if (res.success) {
          this.$message.success('重置成功')
          // this.$refs.table.refresh()
        } else {
          this.$message.error('重置失败：' + res.message)
        }
      })
    },

    /**
     * 删除用户
     * @param record
     */
    sysUserDelete(record) {
      sysUserDelete({id: record.id, status:2})
        .then((res) => {
          if (res.success) {
            this.$message.success(record.name + '已删除')
            this.$refs.table.refresh()
          } else {
            this.$message.error('删除失败：' + res.message)
          }
        })
        .catch((err) => {
          this.$message.error('删除错误：' + err.message)
        })
    },

    /**
     * 点击左侧机构树查询列表
     */
    handleClick(e) {
      this.queryParam = {
        'sysEmpParam.orgId': e.toString(),
      }
      this.$refs.table.refresh(true)
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleOk() {
      this.$refs.table.refresh()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>

<style lang="less">
.table-operator {
  margin-bottom: 18px;
}
button {
  margin-right: 8px;
}
.info-button {
  display: flex;
  flex-direction: row;
}
</style>
